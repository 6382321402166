import { buildMediaQueries } from '@rategravity/marketing-components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../modules/colors';
import { ResponsivePrimaryTextMixin } from '../../typography';

const guarantees = [
  'No spam or unwanted calls, ever',
  'No hard credit checks',
  'No sales pressure, cost, or obligation'
];

const GuaranteeList = styled('ul')`
  ${ResponsivePrimaryTextMixin}
  color: ${colors.PRIMARY};
  font-size: 15px;
  ${buildMediaQueries(['medium', 'large'], {
    paddingInlineStart: 0,
    textAlign: 'center',
    '& > li': {
      display: 'inline',
      ':not(:first-child):before': {
        content: "''",
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: '0 10px',
        width: 5,
        height: 5,
        borderRadius: '50%',
        backgroundColor: colors.PRIMARY
      }
    }
  })}
  ${buildMediaQueries('smallAndDown', {
    textAlign: 'left',
    paddingInlineStart: '20px'
  })}
`;

export const Guarantees = () => (
  <GuaranteeList>
    {guarantees.map((guarantee, index) => (
      <li key={`guarantee-${index}`}>{guarantee}</li>
    ))}
  </GuaranteeList>
);
